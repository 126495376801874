.clearfix {
  overflow: hidden;
  *zoom: 1;
}

.font-size-lg, .fs-lg {
  font-size: large !important;
}

.font-size-larger, .fs-larger {
  font-size: larger !important;
}

.font-size-14, .fs-14 {
  font-size: 14px !important;
}

.font-size-16, .fs-16 {
  font-size: 16px !important;
}

.font-size-18, .fs-18 {
  font-size: 18px !important;
}

.font-size-20, .fs-20 {
  font-size: 20px !important;
}

.position-absolute, .pos-abs {
  position: absolute;
}

.position-relative, .pos-rel {
  position: relative;
}

.mt-auto {
  margin-top: auto !important;
}

.mleft--5, .mleft-5 {
  margin-left: -5px !important;
}

.pleft--5, .pleft-5 {
  padding-left: -5px !important;
}

.mleft-0, .mleft0 {
  margin-left: 0px !important;
}

.pleft-0, .pleft0 {
  padding-left: 0px !important;
}

.mleft-5, .mleft5 {
  margin-left: 5px !important;
}

.pleft-5, .pleft5 {
  padding-left: 5px !important;
}

.mleft-10, .mleft10 {
  margin-left: 10px !important;
}

.pleft-10, .pleft10 {
  padding-left: 10px !important;
}

.mleft-15, .mleft15 {
  margin-left: 15px !important;
}

.pleft-15, .pleft15 {
  padding-left: 15px !important;
}

.mleft-20, .mleft20 {
  margin-left: 20px !important;
}

.pleft-20, .pleft20 {
  padding-left: 20px !important;
}

.mleft-25, .mleft25 {
  margin-left: 25px !important;
}

.pleft-25, .pleft25 {
  padding-left: 25px !important;
}

.mleft-30, .mleft30 {
  margin-left: 30px !important;
}

.pleft-30, .pleft30 {
  padding-left: 30px !important;
}

.mleft-35, .mleft35 {
  margin-left: 35px !important;
}

.pleft-35, .pleft35 {
  padding-left: 35px !important;
}

.mleft-40, .mleft40 {
  margin-left: 40px !important;
}

.pleft-40, .pleft40 {
  padding-left: 40px !important;
}

.mleft-45, .mleft45 {
  margin-left: 45px !important;
}

.pleft-45, .pleft45 {
  padding-left: 45px !important;
}

.mleft-50, .mleft50 {
  margin-left: 50px !important;
}

.pleft-50, .pleft50 {
  padding-left: 50px !important;
}

.mright--5, .mright-5 {
  margin-right: -5px !important;
}

.pright--5, .pright-5 {
  padding-right: -5px !important;
}

.mright-0, .mright0 {
  margin-right: 0px !important;
}

.pright-0, .pright0 {
  padding-right: 0px !important;
}

.mright-5, .mright5 {
  margin-right: 5px !important;
}

.pright-5, .pright5 {
  padding-right: 5px !important;
}

.mright-10, .mright10 {
  margin-right: 10px !important;
}

.pright-10, .pright10 {
  padding-right: 10px !important;
}

.mright-15, .mright15 {
  margin-right: 15px !important;
}

.pright-15, .pright15 {
  padding-right: 15px !important;
}

.mright-20, .mright20 {
  margin-right: 20px !important;
}

.pright-20, .pright20 {
  padding-right: 20px !important;
}

.mright-25, .mright25 {
  margin-right: 25px !important;
}

.pright-25, .pright25 {
  padding-right: 25px !important;
}

.mright-30, .mright30 {
  margin-right: 30px !important;
}

.pright-30, .pright30 {
  padding-right: 30px !important;
}

.mright-35, .mright35 {
  margin-right: 35px !important;
}

.pright-35, .pright35 {
  padding-right: 35px !important;
}

.mright-40, .mright40 {
  margin-right: 40px !important;
}

.pright-40, .pright40 {
  padding-right: 40px !important;
}

.mright-45, .mright45 {
  margin-right: 45px !important;
}

.pright-45, .pright45 {
  padding-right: 45px !important;
}

.mright-50, .mright50 {
  margin-right: 50px !important;
}

.pright-50, .pright50 {
  padding-right: 50px !important;
}

.mtop--5, .mtop-5 {
  margin-top: -5px !important;
}

.ptop--5, .ptop-5 {
  padding-top: -5px !important;
}

.mtop-0, .mtop0 {
  margin-top: 0px !important;
}

.ptop-0, .ptop0 {
  padding-top: 0px !important;
}

.mtop-5, .mtop5 {
  margin-top: 5px !important;
}

.ptop-5, .ptop5 {
  padding-top: 5px !important;
}

.mtop-10, .mtop10 {
  margin-top: 10px !important;
}

.ptop-10, .ptop10 {
  padding-top: 10px !important;
}

.mtop-15, .mtop15 {
  margin-top: 15px !important;
}

.ptop-15, .ptop15 {
  padding-top: 15px !important;
}

.mtop-20, .mtop20 {
  margin-top: 20px !important;
}

.ptop-20, .ptop20 {
  padding-top: 20px !important;
}

.mtop-25, .mtop25 {
  margin-top: 25px !important;
}

.ptop-25, .ptop25 {
  padding-top: 25px !important;
}

.mtop-30, .mtop30 {
  margin-top: 30px !important;
}

.ptop-30, .ptop30 {
  padding-top: 30px !important;
}

.mtop-35, .mtop35 {
  margin-top: 35px !important;
}

.ptop-35, .ptop35 {
  padding-top: 35px !important;
}

.mtop-40, .mtop40 {
  margin-top: 40px !important;
}

.ptop-40, .ptop40 {
  padding-top: 40px !important;
}

.mtop-45, .mtop45 {
  margin-top: 45px !important;
}

.ptop-45, .ptop45 {
  padding-top: 45px !important;
}

.mtop-50, .mtop50 {
  margin-top: 50px !important;
}

.ptop-50, .ptop50 {
  padding-top: 50px !important;
}

.mbottom--5, .mbottom-5 {
  margin-bottom: -5px !important;
}

.pbottom--5, .pbottom-5 {
  padding-bottom: -5px !important;
}

.mbottom-0, .mbottom0 {
  margin-bottom: 0px !important;
}

.pbottom-0, .pbottom0 {
  padding-bottom: 0px !important;
}

.mbottom-5, .mbottom5 {
  margin-bottom: 5px !important;
}

.pbottom-5, .pbottom5 {
  padding-bottom: 5px !important;
}

.mbottom-10, .mbottom10 {
  margin-bottom: 10px !important;
}

.pbottom-10, .pbottom10 {
  padding-bottom: 10px !important;
}

.mbottom-15, .mbottom15 {
  margin-bottom: 15px !important;
}

.pbottom-15, .pbottom15 {
  padding-bottom: 15px !important;
}

.mbottom-20, .mbottom20 {
  margin-bottom: 20px !important;
}

.pbottom-20, .pbottom20 {
  padding-bottom: 20px !important;
}

.mbottom-25, .mbottom25 {
  margin-bottom: 25px !important;
}

.pbottom-25, .pbottom25 {
  padding-bottom: 25px !important;
}

.mbottom-30, .mbottom30 {
  margin-bottom: 30px !important;
}

.pbottom-30, .pbottom30 {
  padding-bottom: 30px !important;
}

.mbottom-35, .mbottom35 {
  margin-bottom: 35px !important;
}

.pbottom-35, .pbottom35 {
  padding-bottom: 35px !important;
}

.mbottom-40, .mbottom40 {
  margin-bottom: 40px !important;
}

.pbottom-40, .pbottom40 {
  padding-bottom: 40px !important;
}

.mbottom-45, .mbottom45 {
  margin-bottom: 45px !important;
}

.pbottom-45, .pbottom45 {
  padding-bottom: 45px !important;
}

.mbottom-50, .mbottom50 {
  margin-bottom: 50px !important;
}

.pbottom-50, .pbottom50 {
  padding-bottom: 50px !important;
}

.m-5 {
  margin: -5px !important;
}

.p-5 {
  padding: -5px !important;
}

.m0 {
  margin: 0px !important;
}

.p0 {
  padding: 0px !important;
}

.m5 {
  margin: 5px !important;
}

.p5 {
  padding: 5px !important;
}

.m10 {
  margin: 10px !important;
}

.p10 {
  padding: 10px !important;
}

.m15 {
  margin: 15px !important;
}

.p15 {
  padding: 15px !important;
}

.m20 {
  margin: 20px !important;
}

.p20 {
  padding: 20px !important;
}

.m25 {
  margin: 25px !important;
}

.p25 {
  padding: 25px !important;
}

.m30 {
  margin: 30px !important;
}

.p30 {
  padding: 30px !important;
}

.m35 {
  margin: 35px !important;
}

.p35 {
  padding: 35px !important;
}

.m40 {
  margin: 40px !important;
}

.p40 {
  padding: 40px !important;
}

.m45 {
  margin: 45px !important;
}

.p45 {
  padding: 45px !important;
}

.m50 {
  margin: 50px !important;
}

.p50 {
  padding: 50px !important;
}

.w-10 {
  width: -10px !important;
}

.h-10 {
  width: -10px !important;
}

.w0 {
  width: 0px !important;
}

.h0 {
  width: 0px !important;
}

.w10 {
  width: 10px !important;
}

.h10 {
  width: 10px !important;
}

.w20 {
  width: 20px !important;
}

.h20 {
  width: 20px !important;
}

.w30 {
  width: 30px !important;
}

.h30 {
  width: 30px !important;
}

.w40 {
  width: 40px !important;
}

.h40 {
  width: 40px !important;
}

.w50 {
  width: 50px !important;
}

.h50 {
  width: 50px !important;
}

.w60 {
  width: 60px !important;
}

.h60 {
  width: 60px !important;
}

.w70 {
  width: 70px !important;
}

.h70 {
  width: 70px !important;
}

.w80 {
  width: 80px !important;
}

.h80 {
  width: 80px !important;
}

.w90 {
  width: 90px !important;
}

.h90 {
  width: 90px !important;
}

.w100 {
  width: 100px !important;
}

.h100 {
  width: 100px !important;
}

.w110 {
  width: 110px !important;
}

.h110 {
  width: 110px !important;
}

.w120 {
  width: 120px !important;
}

.h120 {
  width: 120px !important;
}

.w130 {
  width: 130px !important;
}

.h130 {
  width: 130px !important;
}

.w140 {
  width: 140px !important;
}

.h140 {
  width: 140px !important;
}

.w150 {
  width: 150px !important;
}

.h150 {
  width: 150px !important;
}

.w160 {
  width: 160px !important;
}

.h160 {
  width: 160px !important;
}

.w170 {
  width: 170px !important;
}

.h170 {
  width: 170px !important;
}

.w180 {
  width: 180px !important;
}

.h180 {
  width: 180px !important;
}

.w190 {
  width: 190px !important;
}

.h190 {
  width: 190px !important;
}

.w200 {
  width: 200px !important;
}

.h200 {
  width: 200px !important;
}

.w210 {
  width: 210px !important;
}

.h210 {
  width: 210px !important;
}

.w220 {
  width: 220px !important;
}

.h220 {
  width: 220px !important;
}

.w230 {
  width: 230px !important;
}

.h230 {
  width: 230px !important;
}

.w240 {
  width: 240px !important;
}

.h240 {
  width: 240px !important;
}

.w250 {
  width: 250px !important;
}

.h250 {
  width: 250px !important;
}

.w260 {
  width: 260px !important;
}

.h260 {
  width: 260px !important;
}

.w270 {
  width: 270px !important;
}

.h270 {
  width: 270px !important;
}

.w280 {
  width: 280px !important;
}

.h280 {
  width: 280px !important;
}

.w290 {
  width: 290px !important;
}

.h290 {
  width: 290px !important;
}

.w300 {
  width: 300px !important;
}

.h300 {
  width: 300px !important;
}

.w310 {
  width: 310px !important;
}

.h310 {
  width: 310px !important;
}

.w320 {
  width: 320px !important;
}

.h320 {
  width: 320px !important;
}

.w330 {
  width: 330px !important;
}

.h330 {
  width: 330px !important;
}

.w340 {
  width: 340px !important;
}

.h340 {
  width: 340px !important;
}

.w350 {
  width: 350px !important;
}

.h350 {
  width: 350px !important;
}

.w360 {
  width: 360px !important;
}

.h360 {
  width: 360px !important;
}

.w370 {
  width: 370px !important;
}

.h370 {
  width: 370px !important;
}

.w380 {
  width: 380px !important;
}

.h380 {
  width: 380px !important;
}

.w390 {
  width: 390px !important;
}

.h390 {
  width: 390px !important;
}

.w400 {
  width: 400px !important;
}

.h400 {
  width: 400px !important;
}

.w410 {
  width: 410px !important;
}

.h410 {
  width: 410px !important;
}

.w420 {
  width: 420px !important;
}

.h420 {
  width: 420px !important;
}

.w430 {
  width: 430px !important;
}

.h430 {
  width: 430px !important;
}

.w440 {
  width: 440px !important;
}

.h440 {
  width: 440px !important;
}

.w450 {
  width: 450px !important;
}

.h450 {
  width: 450px !important;
}

.w460 {
  width: 460px !important;
}

.h460 {
  width: 460px !important;
}

.w470 {
  width: 470px !important;
}

.h470 {
  width: 470px !important;
}

.w480 {
  width: 480px !important;
}

.h480 {
  width: 480px !important;
}

.w490 {
  width: 490px !important;
}

.h490 {
  width: 490px !important;
}

.w500 {
  width: 500px !important;
}

.h500 {
  width: 500px !important;
}

.w510 {
  width: 510px !important;
}

.h510 {
  width: 510px !important;
}

.w520 {
  width: 520px !important;
}

.h520 {
  width: 520px !important;
}

.w530 {
  width: 530px !important;
}

.h530 {
  width: 530px !important;
}

.w540 {
  width: 540px !important;
}

.h540 {
  width: 540px !important;
}

.w550 {
  width: 550px !important;
}

.h550 {
  width: 550px !important;
}

.w560 {
  width: 560px !important;
}

.h560 {
  width: 560px !important;
}

.w570 {
  width: 570px !important;
}

.h570 {
  width: 570px !important;
}

.w580 {
  width: 580px !important;
}

.h580 {
  width: 580px !important;
}

.w590 {
  width: 590px !important;
}

.h590 {
  width: 590px !important;
}

.w600 {
  width: 600px !important;
}

.h600 {
  width: 600px !important;
}

.flex {
  display: flex;
}

.flex-centered-horizontal {
  display: flex;
  justify-content: center;
}

.flex-centered-vertical {
  display: flex;
  align-items: center;
}

.flex-centered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

[ng\:cloak],
[ng-cloak],
[data-ng-cloak],
[x-ng-cloak],
.ng-cloak,
.x-ng-cloak,
.ng-hide:not(.ng-hide-animate) {
  display: none !important;
}

.text-left {
  text-align: left !important;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}
